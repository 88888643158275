<template>
  <div class="container student-attestation mt-4 mb-4">
    <h4 class="text-center mb-4">Успеваемость за текущий семестр</h4>

    <div class="mt-4 mb-4 card">
      <div class="card-body">

        <div class="card-title mb-4 ml-3">
          <h2 class="d-block" style="font-size: 1.3rem; font-weight: bold;">
            Информация о студенте
          </h2>
        </div>

        <div>
          <p>
            <strong>ФИО:</strong> {{ studentInfos?.last_name }} {{ studentInfos?.first_name }}
            {{studentInfos?.middle_name }}
          </p>
          <p><strong>Баркод:</strong> {{ studentInfos?.barcode }}</p>
          <p>
            <strong>Специальность или ОП:</strong>
            {{studentInfos?.educationProgram?.education_speciality_code }}
            {{studentInfos?.educationProgram?.education_speciality_name != 'Smart medicine' ? studentInfos?.educationProgram?.education_speciality_name : 'General medicine'}}
          </p>
          <p><strong>Уровень обучения:</strong> {{ studentInfos?.studyLevel?.name }}</p>
          <p><strong>Форма обучения:</strong> {{ studentInfos?.studyForm?.name }}</p>
          <p><strong>Курс:</strong> {{ studentInfos?.study_course }}</p>
          <p><strong>Группа:</strong> {{ studentInfos?.studentGroups?.name }}</p>
        </div>

      </div>
    </div>


    <DataTable :value="attestationInfos"
               filterDisplay="menu"
               v-model:filters="filters"
               stripedRows responsiveLayout="scroll">
      <Column field="course_name" header="Название курса">
        <template #body="{data}">
          <a :href="'https://moodle.uib.kz/course/view.php?id='+ data.mdl_course_id" target="_blank">{{data.course_name}}</a>
        </template>
      </Column>

      <Column header="Преподаватель">
        <template #body="{data}">
          <span>{{data.pps_data_lastname}} {{data.pps_data_firstname}}</span>
        </template>
      </Column>

      <Column field="semester" header="Семестр">
        <template #filter="{filterModel}">
          <Dropdown v-model="filterModel.value" :options="semesters" placeholder="Любой"
                    class="p-column-filter" :showClear="true">
            <template #value="slotProps">
              <span v-if="slotProps.value">{{slotProps.value}}</span>
              <span v-else>{{slotProps.placeholder}}</span>
            </template>
            <template #option="slotProps">
              <span>{{slotProps.option}}</span>
            </template>
          </Dropdown>
        </template>
      </Column>
      <Column field="credit" header="Кредит"></Column>
      <Column field="vsk1" header="ВСК1"></Column>
      <Column field="vsk2" header="ВСК2"></Column>
      <Column field="exam" header="Экзамен">

        <template #body="{data}">
            <div v-if = "data.status_exam==1">
              <a href="#" @click="openEditExamGradeDialog(data.rating_id)">{{data.exam}}</a>
            </div>
        </template>

      </Column>
      <Column field="total" header="Общий балл"></Column>
      <Column field="num_gpa" header="GPA"></Column>
      <Column field="letter_equiv" header="Grade"></Column>

      <Column header="Силлабус">
        <template #body="{data}">
          <a :href="`syllabus_data/syllabus-print/get-syllabus?user_id=${data.user_id}&education_discipline_id=${data.education_discipline_id}`"
             target="_blank" class="btn btn-primary m-1">
            <i class="pi pi-print"></i>
          </a>
        </template>
      </Column>

      <Column header="Удаление">
        <template #body="{data}">
          <button class="btn btn-danger m-1" @click="openDeleteRatingDialog(data.rating_id)">
                      <span v-if="deletionRating&&data.rating_id==deleteRatingId" class="spinner-grow spinner-grow-sm"
                            role="status"
                            aria-hidden="true"></span>
            <i v-else class="pi pi-trash"></i>
          </button>
        </template>
      </Column>
    </DataTable>

    <ConfirmDialog group="deleteRatingDialog"></ConfirmDialog>

    <Dialog v-model:visible="editExamGradeDisplay" :style="{width: '50vw'}">
      <template #header>
        <h5>Поменять оценку за экзамен</h5>
      </template>

      <div style="height: 400px" class="col-md-12 mt-4">
        <div>
          <div class="row mt-4">
            <div class="col-md-3">
              <p>Новый балл за экзамен</p>
            </div>
            <div class="col-md-9">
              <input class="form-control" id="number" type="number" max="100" min="0" placeholder="Экзамен" @change="changeExamGrade($event)">
            </div>
          </div>
        </div>
      </div>

      <template #footer>
        <Button label="Нет" icon="pi pi-times" class="p-button-text" @click="closeEditExamGradeDialog"/>
        <Button label="Да" icon="pi pi-check" @click="putExamGrade"/>
      </template>
    </Dialog>

  </div>
</template>

<script>
  import {FilterMatchMode, FilterOperator} from 'primevue/api';
  import {mapGetters, mapActions, mapMutations, mapState} from "vuex";
  import httpClient from "../../services/http.service";
  import {getCookie} from "@/utils/helpers/cookies.helpers";
  export default {
    name: "StudentCurrentAttestation",
    data() {
      return {
        attestationInfos: [],
        urlBarcode: +this.$route.query.barcode || 0,
        studentInfos: [],
        deleteRatingId: 0,
        deletionRating: false,
        editExamGradeDisplay: false,
        ratingId: 0,
        filters: {
          'semester': {value: null, matchMode: FilterMatchMode.EQUALS},
        },
        semesters: [1, 2 , 3 , 4 , 5 , 6, 7, 8],
      }
    },
    methods: {
      ...mapActions('studentsList', ['PUT_EXAM_GRADE']),
      ...mapMutations('studentsList', ['UPDATE_EXAM_GRADE_RATING_ID', 'UPDATE_EXAM_GRADE', 'CLEAR_EXAM_GRADE_REPORT']),

      async putExamGrade(){
        this.editExamGradeDisplay = false
        await this.PUT_EXAM_GRADE()
        await this.getStudentAttestation()
      },
      changeExamGrade(e){
        let exam = e.target.value

        this.UPDATE_EXAM_GRADE(exam)
      },

      openEditExamGradeDialog(rating_id){
        this.CLEAR_EXAM_GRADE_REPORT()
        this.editExamGradeDisplay = true
        this.ratingId = rating_id
        console.log(rating_id, 'rating_id')
        this.UPDATE_EXAM_GRADE_RATING_ID(rating_id)
      },

      closeEditExamGradeDialog(){
        this.editExamGradeDisplay = false
        this.CLEAR_EXAM_GRADE_REPORT()
      },

      async getStudentDataByBarcode() {
        try {
          const url = `student_data/student-data/get-info-by-barcode?access-token=${getCookie('ACCESS_TOKEN')}&barcode=${this.urlBarcode}`
          const {
            status,
            data
          } = await httpClient.get(url);
          if (status === 200) {
            this.studentInfos = data.success
          }
        } catch (e) {
          console.log(e.response)
        }
      },
      async getStudentAttestation() {
        try {
          const url = `student/attestation/index?access-token=${getCookie('ACCESS_TOKEN')}&barcode=${this.urlBarcode}`
          const {
            status,
            data
          } = await httpClient.get(url);
          if (status === 200) {
            this.attestationInfos = data.success
          }
        } catch (e) {
          console.log(e.response)
        }
      },
      async deleteRating(id) {
        try {
          const url = `student/student-ratings/delete?access-token=${getCookie('ACCESS_TOKEN')}&id=${id}`
          const {status} = await httpClient.delete(url)
          if (status === 200) {
            await this.getStudentAttestation()
            this.$message({text: 'Курс успешно удален'})
          }
        } catch (e) {
          console.log(e.response)
          this.$error({text: 'При удалении возникла ошибка'})
        }
      },
      openDeleteRatingDialog(id) {
        this.deleteRatingId = id
        let courseName = this.attestationInfos.find(i => i.rating_id == this.deleteRatingId)?.course_name
        this.$confirm.require({
          group: 'deleteRatingDialog',
          message: `Вы точно хотите удалить курс ${courseName} у студента`,
          header: 'Удаление курса у студента',
          icon: 'pi pi-info-circle',
          acceptClass: 'p-button-danger',
          accept: async () => {
            this.deletionRating = true
            await this.deleteRating(this.deleteRatingId)
            this.deletionRating = false
          }
        })
      },
    },

    async mounted() {
      await this.getStudentDataByBarcode()
      await this.getStudentAttestation()
    }
  }
</script>

<style scoped>
  .grade {
    width: 65px;
    height: 65px;
  }

  .grade-text {
    text-align: center;
    vertical-align: middle;
    line-height: 65px;
  }

  .category-text {
    display: flex;
    align-items: flex-end;
    justify-content: center;
  }
</style>
